var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "page-wrapper" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-header-inner" }, [
              _vm._v("Order ID #" + _vm._s(_vm.order.id))
            ])
          ])
        ]),
        _c("div", { staticClass: "page-main" }, [
          _c("div", { staticClass: "order__wrapper" }, [
            _c("div", { staticClass: "order__part" }, [
              _c("div", { staticClass: "order__customer" }, [
                _vm._m(0),
                _c("div", { staticClass: "order__customer-name" }, [
                  _vm._v(_vm._s(_vm.order.pickUpName))
                ]),
                _c("div", { staticClass: "order__customer-address" }, [
                  _vm._v("Address: " + _vm._s(_vm.order.pickUpAddress))
                ])
              ]),
              _c("div", { staticClass: "order__customer" }, [
                _vm._m(1),
                _c("div", { staticClass: "order__customer-name" }, [
                  _vm._v(_vm._s(_vm.order.dropOffName))
                ]),
                _c("div", { staticClass: "order__customer-address" }, [
                  _vm._v("Address: " + _vm._s(_vm.order.dropOffAddress))
                ])
              ])
            ]),
            _c("div", { staticClass: "order__part" }, [
              _c("div", { staticClass: "order__row" }, [
                _c("div", { staticClass: "order__row-title" }, [
                  _vm._v("Date of delivery")
                ]),
                _c("div", { staticClass: "order__row-subtitle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.moment.utc(_vm.order.routeDate).format("DD MMM YYYY")
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "order__part order__part--third" }, [
                _c("div", { staticClass: "order__row" }, [
                  _c("div", { staticClass: "order__row-title" }, [
                    _vm._v("Service")
                  ]),
                  _c("div", { staticClass: "order__row-subtitle" }, [
                    _vm._v(
                      _vm._s(_vm.order.serviceName) +
                        " – " +
                        _vm._s(_vm.order.servicePrice) +
                        " $"
                    )
                  ])
                ]),
                _c("div", { staticClass: "order__row" }, [
                  _c("div", { staticClass: "order__row-title" }, [
                    _vm._v("Fuel Surcharge")
                  ]),
                  _c("div", { staticClass: "order__row-subtitle" }, [
                    _vm._v(_vm._s(_vm.order.fuelSurcharge) + " $")
                  ])
                ]),
                _c("div", { staticClass: "order__row" }, [
                  _c("div", { staticClass: "order__row-title" }, [
                    _vm._v("HST")
                  ]),
                  _c("div", { staticClass: "order__row-subtitle" }, [
                    _vm._v(_vm._s(_vm.order.hst) + " $")
                  ])
                ]),
                _c("div", { staticClass: "order__row" }, [
                  _c("div", { staticClass: "order__row-title" }, [
                    _vm._v("Total")
                  ]),
                  _c("div", { staticClass: "order__row-subtitle" }, [
                    _vm._v(_vm._s(_vm.order.total) + " $")
                  ])
                ])
              ]),
              _vm.order.notes
                ? _c("div", { staticClass: "order__row" }, [
                    _c("div", { staticClass: "order__row-title" }, [
                      _vm._v("Notes")
                    ]),
                    _c("div", { staticClass: "order__row-subtitle" }, [
                      _vm._v(_vm._s(_vm.order.notes))
                    ])
                  ])
                : _vm._e(),
              _vm.order.attachmentPath
                ? _c(
                    "div",
                    { staticClass: "order__row" },
                    [
                      _c("div", { staticClass: "order__row-title" }, [
                        _vm._v("Attachment")
                      ]),
                      _c(
                        "el-image",
                        {
                          attrs: {
                            src: _vm.order.attachmentPath,
                            "preview-src-list": [_vm.order.attachmentPath],
                            fit: "cover"
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "order__image-error",
                            attrs: { slot: "error" },
                            slot: "error"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            (_vm.order.status === 6 && _vm.mePay) ||
            (_vm.order.status === 5 && _vm.mePay)
              ? _c("div", { staticClass: "order__part order__buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button--green order__button",
                      on: { click: _vm.pay }
                    },
                    [_vm._v("Pay")]
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order__customer-icon" }, [
      _c(
        "div",
        { staticClass: "icon-button icon-button--round icon-button--blue" },
        [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order__customer-icon" }, [
      _c(
        "div",
        { staticClass: "icon-button icon-button--round icon-button--orange" },
        [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }