var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-modal-wrapper" },
    [
      _c("Payment"),
      _c("div", { staticClass: "payment-modal__buttons" }, [
        _c(
          "button",
          { staticClass: "button button--red", on: { click: _vm.cancelOrder } },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "button button--green",
            class: { "button--disabled": _vm.disabled },
            on: { click: _vm.payOrder }
          },
          [_vm._v("Pay")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }